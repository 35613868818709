import React from 'react';

const Partners02 = (props) => {
    return (
        <div className={`patner_2 ${props.pt} py-10 bg-neutral-300`}>
        <div className="section_header text-center">
                    {/* <div className="shadow_icon"><img src="images/about/shadow_icon1.png" alt="" /></div>
                    <h6 className="section_sub_title">ABOUT BUILDERRINE CONSTRUCTION</h6> */}
                    <h1 className="section_title">Наши партнеры</h1>
                </div>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="patner_flex flex space-x-20" data-scroll data-scroll-direction="horizontal" data-scroll-speed="-6">
                            <div className="patner_2">
                                <img src="images/patner/main/bz.png" alt="" />
                            </div>
                            <div className="patner_2">
                                <img src="images/patner/main/el.png" alt="" />
                            </div>
                            <div className="patner_2">
                                <img src="images/patner/main/rem.png" alt="" />
                            </div>
                            <div className="patner_2">
                                <img src="images/patner/main/fd.png" alt="" />
                            </div>
                            <div className="patner_2">
                                <img src="images/patner/main/gp.png" alt="" />
                            </div>
                            <div className="patner_2">
                                <img src="images/patner/main/st.png" alt="" />
                            </div>
                            <div className="patner_2">
                                <img src="images/patner/main/tt.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partners02;