import React from 'react';

const Slider = () => {
    return (
        <div className="theme_slider_1">
            <div className="slider">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="slide_content mb-20 sm:mb-40">
                                {/* <h5 className="sub_heading">Связаться с нами<span> +7(911)019-10-17</span></h5> */}
                                <h2 className="heading mt-1">Утепление Эковатой</h2>
                                <p className="desc mt-2">Европейские стандарты качества и скандинавские материалы</p>
                                {/* <div className="slider_button">
                                    <a href="/about" className="button">Подробнее</a>
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-5 d-flex align-items-end">
                            <div className="layer_object">
                                {/* <img src="images/slider/layer_2.png" alt="" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slider;